import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useAppSelector } from '@@src/hooks/store';
import PageStructuredData from '@@src/utils/StructuredData/PageStructuredData';
import { getIsLoggedIn, getIsReady } from '@@stores/UserStore';
import OnDemand from '@@types/OnDemand';
import { SSRFunctionComponent } from '@@types/ssr';
import StructuredData from '@@utils/StructuredData/StructuredData';
import { restoreLocationScrollPosition } from '@@utils/helpers';

import fifaWorldCup2026 from '../../static/images/FifaWorldCup2026-Page.png';
import { getPageBySlug } from '../apis/CatalogueApi';
import { NotFoundError } from '../components/Error/Error';
import PageRows from '../components/PageRows/PageRows';
import grey from '../styles/colors/grey';
import PageLayout from './PageLayout';

export interface PageProps {
  /** If ssr404 is true, it means that the page does not exist when requested from the server side.
   * This prop will always be false on the client side.
   * Unless on a special case where the pathname is /home, it will always 404.
   */
  ssr404?: boolean;
  slug: string;
  refreshInterval?: number;
}

const Page: SSRFunctionComponent<PageProps> = (props) => {
  const {
    staticContext,
    slug,
    ssr404 = false,
    onLoadSuccess = () => {
    },
    onLoadError = () => {
    },
  } = props;

  const userStateReady = useAppSelector(getIsReady);
  const loggedIn = useAppSelector(getIsLoggedIn);
  const [restoreScroll, setRestoreScroll] = useState(false);

  const [pageData, setPageData] = useState<OnDemand.Page>();
  const [exists, setExists] = useState<boolean | undefined>(!ssr404);
  const { i18n: { language } } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (ssr404) {
      setExists(false);
    }
  }, [ssr404]);

  useEffect(() => {
    if (ssr404 === false && slug && userStateReady) {
      getPageBySlug(slug, language, loggedIn)
        .then((data) => {
          setPageData(data);
          setRestoreScroll(true);
          setExists(true);
        })
        .catch(() => {
          setPageData(null);
          setExists(false);
        });
    }
  }, [ssr404, slug, language, exists, loggedIn, userStateReady]);

  useEffect(() => {
    if (restoreScroll) {
      restoreLocationScrollPosition(location, 100);
      setRestoreScroll(false);
    }
  }, [restoreScroll, location]);

  const pageDataRows = get(pageData, 'rows', []);
  const title = slug === 'home' ? '' : get(pageData, 'title', '');
  const description = get(pageData, 'description', '');

  useEffect(() => {
    if (exists === false) {
      onLoadError('404', 'Page Not Found');
    } else if (pageData) {
      onLoadSuccess(title);
    }
  }, [pageData, onLoadSuccess, onLoadError, title, exists]);

  // page doesn't exist
  if (!exists) {
    // if static context available, set the status code to 404 for ssr
    if (staticContext) {
      staticContext.statusCode = 404;
    }

    return <NotFoundError/>;
  }

  // still being loaded
  if (typeof pageData === 'undefined') {
    return (
      <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: grey.darkBlackPearl,
        zIndex: 99,
      }}
      />
    );
  }

  const titleLogo = slug === 'fifa-world-cup-2026' ? fifaWorldCup2026 : null;

  const isFirstRowHeroCarousel = get(pageData, 'rows.0.type') === 'carousel';
  const heroImage = isFirstRowHeroCarousel ? null : pageData.heroImage;

  const pageStructuredData = PageStructuredData.generate(
    pageData,
    language,
  );

  return (
    <PageLayout
      title={title}
      description={description}
      path={location.pathname}
      heroImage={heroImage}
      ogImage={pageData.ogImage}
      sponsorship={pageData.sponsorship}
      titleLogo={titleLogo}
    >
      <StructuredData>
        {JSON.stringify(pageStructuredData)}
      </StructuredData>
      <PageRows rows={pageDataRows}/>
    </PageLayout>
  );
};

Page.refreshInterval = 900000;

Page.getInitialProps = ({
  pathname,
  language,
  ssr,
}) => {
  // this should 404
  if (pathname === '/home') {
    return Promise.resolve({
      slug: '',
      ssr404: true,
    });
  }

  // slug does not have a leading slash, pathname does
  const slug = pathname.replace(/^\//g, '') || 'home';

  // we still need to get page data on the server to check if the page exists
  // if it doesn't we will return a 404 status code
  if (ssr) {
    return getPageBySlug(slug, language)
      .then((pageData) => {
        return {
          slug,
          ssr404: pageData === null,
        };
      });
  }

  return Promise.resolve({
    slug,
    ssr404: false,
  });
};

export default Page;
